import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";


import "./App.css";
import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import About from "./Pages/About";
import Datos_reales_eol from "./Pages/Datos_reales_eol";
import Root from "./Root";
import Bodycomponent from "./Pages/Bodycomponent";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Bodycomponent titulo="Curva de Potencia" subtitulo="Control de Curva de Potencia" link="https://app.powerbi.com/view?r=eyJrIjoiOTU0MzBhMmYtNTllMS00MDA0LWFjMGItZWIzZDVhYzg0ODE5IiwidCI6ImNhMjQxNGM0LTAzNzgtNDk3Yi04YWM2LTdkYzg2MzVhYTllMyIsImMiOjR9&pageName=ReportSection893acc01cae7c19c6c65"/>
      },
      {
        path: "/pronosticos",
        element: <Bodycomponent titulo="Análsis de performance" subtitulo="Pronósticos" link="https://app.powerbi.com/view?r=eyJrIjoiYmVmN2Y0OGEtYzFiNi00NWRiLWE1OGUtM2YwY2NkMzFlMmM4IiwidCI6ImNhMjQxNGM0LTAzNzgtNDk3Yi04YWM2LTdkYzg2MzVhYTllMyIsImMiOjR9&pageName=ReportSection3e6457b9b3b22df65e97"/>
      },
      {
        path: "/datos-reales-eol",
        element: <Bodycomponent titulo="Análsis de performance" subtitulo="Datos Reales Eólico" link="https://app.powerbi.com/view?r=eyJrIjoiYmVmN2Y0OGEtYzFiNi00NWRiLWE1OGUtM2YwY2NkMzFlMmM4IiwidCI6ImNhMjQxNGM0LTAzNzgtNDk3Yi04YWM2LTdkYzg2MzVhYTllMyIsImMiOjR9&pageName=ReportSection012cfc2a272d7e4481b5"/>
      },
      {
        path: "/tabla-historico-datos-reales",
        element: <Bodycomponent titulo="Análsis de performance" subtitulo="Tabla Histórico Datos Reales" link="https://app.powerbi.com/view?r=eyJrIjoiYmVmN2Y0OGEtYzFiNi00NWRiLWE1OGUtM2YwY2NkMzFlMmM4IiwidCI6ImNhMjQxNGM0LTAzNzgtNDk3Yi04YWM2LTdkYzg2MzVhYTllMyIsImMiOjR9&pageName=ReportSectionee908d12da2e3e6610aa"/>
      },
      {
        path: "/tablero-curva-potencia",
        element: <Bodycomponent titulo="Curva de Potencia" subtitulo="Control de Curva de Potencia" link="https://app.powerbi.com/view?r=eyJrIjoiOTU0MzBhMmYtNTllMS00MDA0LWFjMGItZWIzZDVhYzg0ODE5IiwidCI6ImNhMjQxNGM0LTAzNzgtNDk3Yi04YWM2LTdkYzg2MzVhYTllMyIsImMiOjR9&pageName=ReportSection893acc01cae7c19c6c65"/>
      },
      {
        path: "/potencias-ag",
        element: <Bodycomponent titulo="Curva de Potencia" subtitulo="Potencias Aerogeneradores" link="https://app.powerbi.com/view?r=eyJrIjoiOTU0MzBhMmYtNTllMS00MDA0LWFjMGItZWIzZDVhYzg0ODE5IiwidCI6ImNhMjQxNGM0LTAzNzgtNDk3Yi04YWM2LTdkYzg2MzVhYTllMyIsImMiOjR9&pageName=ReportSection3e1ec5b0ca93bbe7502a"/>
      }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}
export default App;
