import React from "react";
import { NavLink, Outlet } from "react-router-dom";

function Root() {
  return (
    <div className="App">
    
  
        <header className="navbar navbar-expand-md d-print-none">
          <div className="container-xl">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <h1  className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
              <a href=".">
                <img style={{height: "3rem"}} src="https://ageera.com.ar/wp-content/uploads/2018/09/ypf12-300x100.png" width={110} height={32} alt="Tabler" className="navbar-brand-image" />
              </a>
            </h1>
            <div className="navbar-nav flex-row order-md-last">
              <div className="nav-item d-none d-md-flex me-3">
                <div className="btn-list">
                 
                  
                </div>
              </div>
              <div className="d-none d-md-flex">
                <a href="?theme=dark" className="nav-link px-0 hide-theme-dark" title="Enable dark mode" data-bs-toggle="tooltip" data-bs-placement="bottom">
                  
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" /></svg>
                </a>
                <a href="?theme=light" className="nav-link px-0 hide-theme-light" title="Enable light mode" data-bs-toggle="tooltip" data-bs-placement="bottom">
                
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
                </a>
                <div className="nav-item dropdown d-none d-md-flex me-3">
                  
                
                </div>
              </div>
              <div className="nav-item dropdown">
                <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                  <span className="avatar avatar-sm" style={{backgroundImage: 'url(./static/avatars/000m.jpg)'}} />
                  <div className="d-none d-xl-block ps-2">
                    <div>Usuario demo</div>
                    <div className="mt-1 small text-secondary">Analista</div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <a href="./profile.html" className="dropdown-item">Perfil</a>
                  <div className="dropdown-divider" />
                  <a href="./settings.html" className="dropdown-item">Opciones</a>
                  <a href="./sign-in.html" className="dropdown-item">Salir</a>
                </div>
              </div>
            </div>
          </div>
        </header>
        <header className="navbar-expand-md">
          <div className="collapse navbar-collapse" id="navbar-menu">
            <div className="navbar">
              <div className="container-xl">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="./">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-windmill"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2.76 0 5 -2.01 5 -4.5s-2.24 -4.5 -5 -4.5v9z" /><path d="M12 12c0 2.76 2.01 5 4.5 5s4.5 -2.24 4.5 -5h-9z" /><path d="M12 12c-2.76 0 -5 2.01 -5 4.5s2.24 4.5 5 4.5v-9z" /><path d="M12 12c0 -2.76 -2.01 -5 -4.5 -5s-4.5 2.24 -4.5 5h9z" /></svg>
                      </span>
                      <span className="nav-link-title">
                        Inicio
                      </span>
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-speedtest"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5.636 19.364a9 9 0 1 1 12.728 0" /><path d="M16 9l-4 4" /></svg>
                      </span>
                      <span className="nav-link-title">
                        Performace
                      </span>
                    </a>
                    <div className="dropdown-menu">
                      <div className="dropdown-menu-columns">
                        <div className="dropdown-menu-column" onClick={e => {document.body.click()}}>
                        <NavLink className="dropdown-item" to="/pronosticos">
                        Pronósticos 
                        </NavLink>
                        <NavLink className="dropdown-item" to="/datos-reales-eol">
                        Datos Reales Eólico
                        </NavLink>
                        <NavLink className="dropdown-item" to="/tabla-historico-datos-reales">
                        Tabla Histórico Datos Reales
                        </NavLink>
 
                         
                        </div>
                       
                      </div>
                    </div>
                  </li>
                
                 
              
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chart-line"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 19l16 0" /><path d="M4 15l4 -6l4 2l4 -5l4 4" /></svg>
                      </span>
                      <span className="nav-link-title">
                        Curva de Potencia
                      </span>
                    </a>
                    <div className="dropdown-menu">
                      <div className="dropdown-menu-columns">
                        <div className="dropdown-menu-column" onClick={e => {document.body.click()}}>
                        <NavLink className="dropdown-item" to="/tablero-curva-potencia">
                            Control de Curva de Potencia
                        </NavLink>
                        <NavLink className="dropdown-item" to="/potencias-ag">
                        Potencias Aerogeneradores
                        </NavLink>

 
                         
                        </div>
                       
                      </div>
                    </div>
                  </li>


                  
                </ul>
                <div className="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
                  <form action="./" method="get" autoComplete="off" noValidate>
                    <div className="input-icon">
                      <span className="input-icon-addon">
                        
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                      </span>
                      <input type="text" defaultValue className="form-control" placeholder="Buscar..." aria-label="Search in website" value="" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </header>
  
      <main>
      
        <Outlet />
      </main>
    </div>
  );
}

export default Root;
