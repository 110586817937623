import React from "react";

function Bodycomponent(props) {
  return (
  <div class="page-wrapper">
        <div class="page-header d-print-none">
          <div class="container-xl">
            <div class="row g-2 align-items-center">
              <div class="col">
                <h2 class="page-title">
                  {props.titulo}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="page-body">
          <div class="container-xl">
            <div class="row row-cards">
              <div class="col-lg-12">
                <div class="card" style={{height: "760px", overflow: "hidden"}}>
                  <div class="card-body">
                  <div class="d-flex">
                      <h3 class="card-title">{props.subtitulo}</h3>
                      <div class="ms-auto">
                        <div class="dropdown">
                          <a class="dropdown-toggle text-secondary" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Período</a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item active" href="#">Ultimos 7 dias</a>
                            <a class="dropdown-item" href="#">Ultimos 30 dias</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      
                      <iframe allowFullScreen="false" frameborder="0" style={{minHeight: "800px"}} src={props.link}></iframe>
                      
                      
                    </div>
                  </div>
                </div>
              </div>
           
              
     
         
            </div>
          </div>
        </div>
        <footer class="footer footer-transparent d-print-none">
          <div class="container-xl">
            <div class="row text-center align-items-center flex-row-reverse">
              <div class="col-lg-auto ms-lg-auto">
                <ul class="list-inline list-inline-dots mb-0">
                  <li class="list-inline-item"><a href="#" target="_blank" class="link-secondary" rel="noopener">Soporte</a></li>
                  
                </ul>
              </div>
              <div class="col-12 col-lg-auto mt-3 mt-lg-0">
                <ul class="list-inline list-inline-dots mb-0">
                  <li class="list-inline-item">
                    Copyright &copy; 2024
                    <a href="." class="link-secondary"> Performance</a>.
                    demo.
                  </li>
                  <li class="list-inline-item">
                    <a href="./changelog.html" class="link-secondary" rel="noopener">
                      v0.0.1
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        </div>
        )
   
}

export default Bodycomponent;
